<template>
  <div class="experience_order">
    <div class="order_status_nav">
      <div
        :class="[currentOrderType == 0 ? 'active' : '', 'nohandle']"
        @click="changeOrderType(0)"
      >
        <span v-if="orderNums.no_visit"></span>
        未处理（{{ orderNums.no_visit }}）
      </div>
      <div
        :class="[currentOrderType == 1 ? 'active' : '']"
        @click="changeOrderType(1)"
      >
        已处理（{{ orderNums.is_visit }}）
      </div>
      <div
        :class="[currentOrderType == 2 ? 'active' : '']"
        @click="changeOrderType(2)"
      >
        全部（{{ orderNums.total }}）
      </div>
    </div>
    <div class="search_wrap">
      <div class="area_screen">
        <el-cascader
          style="width: 200px"
          placeholder="请选择区域"
          v-model="areaScreen"
          :options="getCities"
          @change="areaScreenFun"
          clearable
        ></el-cascader>
      </div>
      <div>
        <el-input
          placeholder="请输入内容"
          v-model="search_keyword"
          class="input-with-select"
        >
          <el-select v-model="search_type" slot="prepend" placeholder="请选择">
            <el-option label="手机号" value="phone"></el-option>
            <el-option label="小区名称" value="community_name"></el-option>
          </el-select>
          <el-button @click="searchBtn(false)" slot="append">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="manage_order_list_warp">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#eee',
          color: '#333',
          fontSize: '16px',
        }"
      >
        <el-table-column
          v-for="(item, index) in currentColums"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.type == 'text'">{{
              scope.row[item.prop] ? scope.row[item.prop] : '-'
            }}</span>
            <span
              v-else-if="item.type == 'temp' && item.prop == 'customer_type'"
            >
              {{ scope.row.customer_type == 1 ? '装修客户' : '软件客户' }}
            </span>
            <span
              v-else-if="item.type == 'temp' && item.prop == 'community_name'"
            >
              {{ scope.row.community_name ? scope.row.community_name : '-' }}
            </span>
            <!-- <span v-else-if="item.type == 'temp' && item.prop == 'info'">
              {{
                scope.row.community_name ? scope.row.address : scope.row.info
              }}
            </span> -->
            <span v-else-if="item.type == 'temp' && item.prop == 'requirement'">
              {{
                scope.row.community_name ? '全屋定制' : scope.row.requirement
              }}
            </span>

            <span v-else-if="item.type == 'temp' && item.prop == 'address'"
              >{{ scope.row.province ? `${scope.row.province}/` : ''
              }}{{ scope.row.city ? `${scope.row.city}/` : ''
              }}{{ scope.row.district }}</span
            >
            <span
              v-else-if="item.type == 'temp' && item.prop == 'customer_source'"
              >{{ scope.row.assign_name }}</span
            >
            <div v-else-if="item.type == 'temp' && item.prop == 'operation'">
              <span
                style="
                  display: inline-block;
                  margin-right: 10px;
                  color: #1890ff;
                  cursor: pointer;
                "
                @click="handleOrder(scope.row)"
                >处理回访</span
              >
              <span
                style="color: #1890ff; cursor: pointer"
                @click="handleOrder(scope.row)"
                >查看详情</span
              >
            </div>
            <div
              style="display: flex; align-items: center"
              v-else-if="item.type == 'temp' && item.prop == 'share_status'"
            >
              <span :style="orderStatusStyle(scope.row[item.prop])"></span
              ><span
                :style="{
                  color: [2, 3, 4].includes(scope.row[item.prop])
                    ? '#00a854'
                    : '#bfbfbf',
                }"
                >{{ shareStatus(scope.row[item.prop]) }}</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="查看详情"
      :visible.sync="orderDetalDialogShow"
      width="30%"
    >
      <div class="orderDetalDialog_wrap">
        <p><span>订单编号：</span>{{ currentOrderInfo.order_id }}</p>
        <p><span>回访者：</span>{{ currentOrderInfo.revisit_name }}</p>
        <p><span>回访时间：</span>{{ currentOrderInfo.revisit_time }}</p>
        <p><span>回访详情：</span>{{ currentOrderInfo.revisit_info }}</p>
        <p>
          <span>线索状态：</span
          >{{ currentOrderInfo.share_status >= 2 ? '有效' : '无效' }}
        </p>
        <p><span>分配给：</span>{{ currentOrderInfo.be_assigned_name }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="orderDetalDialogShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="close"
      title="处理回访"
      :visible.sync="handleDialogShow"
      width="30%"
    >
      <div class="handleDialog_wrap">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="回访者" prop="revisit_name">
            <el-input
              v-model="ruleForm.revisit_name"
              :disabled="currentOrderInfo.share_status != 1 ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择回访时间" prop="revisit_time" required>
            <el-date-picker
              :disabled="currentOrderInfo.share_status != 1 ? true : false"
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.revisit_time"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="回访详情" prop="revisit_info">
            <el-input
              type="textarea"
              :disabled="currentOrderInfo.share_status != 1 ? true : false"
              v-model="ruleForm.revisit_info"
            ></el-input>
          </el-form-item>
          <el-form-item label="线索是否有效" prop="efficient">
            <el-select
              :disabled="currentOrderInfo.share_status != 1 ? true : false"
              v-model="ruleForm.efficient"
              class="m-2"
              placeholder="Select"
              size="large"
            >
              <el-option :key="1" label="有效" :value="1" />
              <el-option :key="0" label="无效" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="分配给" prop="send_give">
            <!-- :props="{ expandTrigger: 'hover' }" -->
            <div
              style="display: flex"
              v-if="currentOrderInfo.customer_type == 1"
            >
              <el-cascader
                style="width: 200px"
                placeholder="请选择"
                v-model="send_give"
                :options="getCities"
                @change="filterAddressCloudeFactFun"
              ></el-cascader>
              <el-select
                :loading="loading"
                filterable
                :remote-method="changeInputValue"
                v-model="selectCloudeValue"
                @change="changeFactFun"
                placeholder="请选择"
                remote
              >
                <el-option
                  v-for="item in selectCloudeFactList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span
                    v-if="selectCloudeFactList.length"
                    style="float: right; color: red; font-size: 13px"
                    >已分配{{ item.order_num }}单</span
                  >
                </el-option>
              </el-select>
            </div>
            <div v-else-if="currentOrderInfo.customer_type == 2">
              <el-select
                :loading="loading"
                filterable
                :remote-method="changeInputValue"
                v-model="selectEmployeesValue"
                @change="changeFactFun"
                placeholder="请选择"
                remote
              >
                <el-option
                  v-for="item in selectEmployeesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span
                    v-if="selectEmployeesList.length"
                    style="float: right; color: red; font-size: 13px"
                    >已分配{{ item.order_num }}单</span
                  >
                </el-option>
              </el-select>
            </div>
            <!-- <el-input
              v-model="ruleForm.phone"
              :disabled="currentOrderInfo.be_assigned_phone ? true : false"
            ></el-input> -->
          </el-form-item>
          <el-form-item label="是否完成">
            <el-switch
              v-model="orderIsDealValue1"
              :disabled="currentOrderInfo.finish_status"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.page"
        :page-sizes="[10, 12, 15, 20]"
        :page-size="form.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total_count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import cityInfoList from '@/utils/area'

export default {
  data() {
    return {
      payload: {
        city: '',
        province: '',
        district: '',
        limit: 10000,
        page: 1,
      },
      loading: false,
      areaScreen: '', //客户地区筛选
      send_give: '', //分配装修的客户的地区筛选值
      selectCloudeFactList: [], //分配装修的客户
      selectEmployeesList: [], //分配软件的客户
      selectCloudeValue: '', //分配的装修客户
      selectEmployeesValue: '', //分配的软件客户
      getCities: cityInfoList,
      orderIsDealValue1: false,
      close: false,
      ruleForm: {
        be_assigned_id: '',
        revisit_name: '',
        revisit_time: '',
        revisit_info: '',
        efficient: 1,
      },
      rules: {
        revisit_name: [
          { required: true, message: '请输入回访者', trigger: 'blur' },
        ],
        revisit_time: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'blur',
          },
        ],
        revisit_info: [
          { required: true, message: '请输入回访详情', trigger: 'blur' },
        ],
        efficient: {
          required: true,
          message: '请选择是否有效',
          trigger: 'blur',
        },
      },
      currentOrderType: 0,
      tableData: [],
      handleDialogShow: false,
      orderDetalDialogShow: false,
      currentOrderInfo: {},
      nohandleColumns: [
        // { prop: 'order_id', label: '订单编号', type: 'text', width: '100' },
        { prop: 'name', label: '客户名称', type: 'text', width: '100' },
        { prop: 'phone', label: '联系电话', type: 'text', width: '120' },
        { prop: 'address', label: '区域', type: 'temp', width: '200' },
        {
          prop: 'community_name',
          label: '小区名称',
          type: 'text',
          width: '200',
        },
        {
          prop: 'customer_source',
          label: '客户来源',
          type: 'temp',
          width: 'auto',
        },
        { prop: 'requirement', label: '客户需求', type: 'temp', width: 'auto' },
        {
          prop: 'customer_type',
          label: '客户类别',
          type: 'temp',
          width: 'auto',
        },
        { prop: 'info', label: '备注', type: 'text', width: 'auto' },
        { prop: 'create_time', label: '提交时间', type: 'text', width: 'auto' },
        { prop: 'operation', label: '操作', type: 'temp', width: '150' },
      ],
      handleColumnsed: [
        { prop: 'name', label: '姓名', type: 'text', width: 'auto' },
        { prop: 'phone', label: '联系方式', type: 'text', width: 'auto' },
        { prop: 'revisit_name', label: '回访者', type: 'text', width: 'auto' },
        { prop: 'create_time', label: '提交时间', type: 'text', width: 'auto' },
        {
          prop: 'revisit_time',
          label: '处理时间',
          type: 'text',
          width: 'auto',
        },
        {
          prop: 'revisit_info',
          label: '回访详情',
          type: 'text',
          width: 'auto',
        },
      ],
      columns: [
        { prop: 'name', label: '姓名', type: 'text', width: 'auto' },
        { prop: 'phone', label: '联系方式', type: 'text', width: 'auto' },
        { prop: 'revisit_name', label: '回访者', type: 'text', width: 'auto' },
        {
          prop: 'revisit_time',
          label: '处理时间',
          type: 'text',
          width: 'auto',
        },
        { prop: 'create_time', label: '提交时间', type: 'text', width: 'auto' },
        {
          prop: 'share_status',
          label: '信息状态',
          type: 'temp',
          width: 'auto',
        },
        { prop: 'operation', label: '操作', type: 'temp', width: 'auto' },
      ],
      form: {
        page: 1,
        limit: 12,
        customer_status: 1,
      },
      total_count: 13,
      search_keyword: '',
      search_type: 'phone',
    }
  },
  computed: {
    orderNums() {
      return this.$store.state.tatal_order_num.experience || {}
    },
    orderStatusStyle() {
      return (status) => {
        return {
          width: '8px',
          height: '8px',
          'border-radius': '50%',
          backgroundColor: [2, 3, 4].includes(status) ? '#00a854' : '#bfbfbf',
          display: 'inline-block',
          marginRight: '5px',
        }
      }
    },
    currentColums() {
      let cureent = []
      switch (this.currentOrderType) {
        case 0:
          cureent = this.nohandleColumns
          break
        case 1:
          cureent = this.handleColumnsed
          break
        case 2:
          cureent = this.columns
          break
      }
      return cureent
    },
  },
  methods: {
    shareStatus(status) {
      switch (status) {
        case -1:
          return '不通过'
        case 0:
          //理论上不会出现这个状态
          return '未分享'
        case 1:
          return '待处理'
        case 2:
          return '审核通过'
        case 3:
          return '已指派'
        case 4:
          return '已成交'
      }
    },
    getEmployees(query) {
      let payload = {
        page: 1,
        limit: 500,
      }
      if (query) {
        payload.phone = query
      }

      this.$request.getEmployees(payload, (res) => {
        console.log('search云工厂===>', res)
        if (res.status == 1) {
          if (res.data.total) {
            this.selectEmployeesList = res.data.data
          } else {
            this.selectEmployeesValue = ''
            this.selectEmployeesList = res.data.data
          }
        }
      })
    },
    // 通过phone筛选云工厂或者达人
    changeInputValue(query) {
      console.log('通过phone筛选的', query)
      let payload = {}
      if (this.currentOrderInfo.customer_type == 1) {
        payload = {
          ...this.payload,
        }
        if (query !== '') {
          payload.phone = query
        }
        this.$request.getSearchCloudFact(payload, (res) => {
          console.log('search云工厂===>', res)
          if (res.status == 1) {
            if (res.data.total) {
              this.selectCloudeFactList = res.data.data
            } else {
              this.selectCloudeValue = ''
              this.selectCloudeFactList = res.data.data
            }
          }
        })
      } else if (this.currentOrderInfo.customer_type == 2) {
        this.getEmployees(query)
      }
    },
    //筛选工厂
    changeFactFun(e) {
      console.log('eqqqq=>>>>', e)
      this.ruleForm.be_assigned_id = e
    },
    //区域筛选客户
    areaScreenFun(e) {
      console.log('e', e)
      console.log('areaScreen', this.areaScreen)
      this.searchBtn(false)
    },
    // 地址筛选工厂 -- 分配工厂使用
    filterAddressCloudeFactFun(e) {
      console.log('ee==>', e)
      this.payload = {
        city: e[1],
        province: e[0],
        district: e[2],
        limit: 10000,
        page: 1,
      }
      this.$request.getSearchCloudFact(this.payload, (res) => {
        console.log('search云工厂===>', res)
        if (res.status == 1) {
          if (res.data.total) {
            this.selectCloudeFactList = res.data.data
          } else {
            this.selectCloudeValue = ''
            this.selectCloudeFactList = res.data.data
          }
        }
      })
    },
    // 搜索按钮
    searchBtn(isPage = false) {
      console.log('isPage', isPage)
      let payload = {
        city: this.areaScreen[1],
        province: this.areaScreen[0],
        district: this.areaScreen[2],
        limit: 12,
        page: isPage ? this.form.page : 1,
      }
      switch (this.search_type) {
        case 'phone':
          payload.phone = this.search_keyword
          break
        case 'community_name':
          payload.community_name = this.search_keyword
          break
      }
      if (this.currentOrderType == 0) {
        payload.is_revisit = 0
      } else if (this.currentOrderType == 1) {
        payload.is_revisit = 1
      }
      this.$request.getTrialRevisitList(payload, (res) => {
        if (res.status == 1) {
          console.log('res', res)
          this.tableData = res.data.customer_list
          // 总数
          this.total_count = res.data.total
        }
      })
    },
    handleSizeChange(value) {
      this.form.limit = value
      this.getList()
    },
    // 回访取消
    cancle(name) {
      this.handleDialogShow = false
      this.$refs[name].resetFields()
      this.send_give = ''
      this.selectCloudeValue = ''
      this.selectEmployeesValue = ''
    },
    // 回访确定
    submitForm(name) {
      let payload = {
        customer_id: this.currentOrderInfo.id,
        finish_status: this.orderIsDealValue1 ? 1 : 0,
        ...this.ruleForm,
        customer_name: this.currentOrderInfo.name,
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$request.toVisit(payload, (res) => {
            if (res.status == 1) {
              this.getList(true)
            }
          })
          this.getList()
          this.handleDialogShow = false
          this.$refs[name].resetFields()
          this.send_give = ''
          this.selectCloudeValue = ''
          this.selectEmployeesValue = ''
          this.ruleForm = {
            revisit_name: '',
            revisit_time: '',
            revisit_info: '',
          }
        } else {
          return false
        }
      })
    },
    changeOrderType(value) {
      this.search_keyword = ''
      this.form.page = 1
      switch (value) {
        case 0:
          this.form.customer_status = 1
          break
        case 1:
          this.form.customer_status = 2
          break
        case 2:
          this.form.customer_status = 0
          break
      }
      this.currentOrderType = value
      this.getList(true)
    },
    handleOrder(row) {
      console.log('row', row)
      if (row.customer_type == 2) {
        this.getEmployees()
      }
      this.currentOrderInfo = row
      this.orderIsDealValue1 = row.finish_status ? true : false
      this.ruleForm = {
        revisit_name: row.revisit_name,
        revisit_time: row.revisit_time,
        revisit_info: row.revisit_info,
        efficient: 1,
      }
      this.handleDialogShow = true
      // if (row.share_status != 1) {
      //   // 查看详情
      //   this.orderDetalDialogShow = true
      // } else {
      //   // 处理回访

      // }
    },
    showOrderDetail(row) {
      this.currentOrderInfo = row
      this.orderDetalDialogShow = true
    },
    handleCurrentChange(page) {
      this.form.page = page
      console.log('????????????', this.search_keyword)
      if (this.search_keyword) {
        this.searchBtn(true)
      } else {
        this.getList()
      }
    },
    getList(update = false) {
      let form = this.form
      this.$request.getTrialRevisitList(
        {
          limit: form.limit,
          page: form.page,
          customer_status: form.customer_status,
        },
        (res) => {
          if (res.status == 1) {
            console.log(res.data.customer_list)
            this.tableData = res.data.customer_list
            // 总数
            this.total_count = res.data.total
            if (update) {
              // 订单状态改变时更新左侧和顶部导航订单数量
              this.$store.dispatch('getOrderNum')
            }
          }
        }
      )
    },
  },
  mounted() {
    this.getList()
    this.getEmployees()
  },
}
</script>

<style lang="less" scoped>
.experience_order {
  padding: 12px;
  .order_status_nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    > div {
      position: relative;
      bottom: -1px;
      padding: 12px 24px;
      cursor: pointer;
      user-select: none;
      &.active {
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
    }
    .nohandle {
      position: relative;
      > span {
        position: absolute;
        top: 8px;
        left: 50%;
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #f5222d;
        border-radius: 50%;
      }
    }
  }
  .search_wrap {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
    .area_screen {
      margin-right: 24px;
    }
    /deep/ .el-select .el-input {
      width: 130px;
    }
    /deep/ .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
    /deep/ .el-input-group__append {
      color: #fff;
      background-color: #1890ff;
      cursor: pointer;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
  .orderDetalDialog_wrap {
    p {
      margin-bottom: 10px;
    }
  }
  .handleDialog_wrap {
  }
}
</style>
